<template>
  <v-dialog v-model="show" max-width="40%">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="editUser"
        v-if="assignedRole.data.permissions.user.write">
        mdi-pencil
      </v-icon>
      <v-icon v-bind="attrs" v-on="on" small @click="deleteUser" v-if="assignedRole.data.permissions.user.delete">
        mdi-delete
      </v-icon>
    </template>
    <v-card>
      <v-card-title class="justify-center">
        {{ formTitle }}
      </v-card-title>
      <v-divider></v-divider>
      <v-progress-linear v-show="loading" indeterminate color="primary" background-opacity="0">
      </v-progress-linear>
      <v-card-text v-if="actionType === 'edit'">
        <v-form ref="editUserForm" lazy-validation class="px-6">
          <v-text-field v-model="user.name" label="Full Name" :rules="rules.nameRules" required>
          </v-text-field>
          <v-text-field v-model="user.email" label="E-mail" :rules="rules.emailRules" required>
          </v-text-field>
          <v-select v-model="user.userRole" :items="roles" :rules="[v => !!v || 'User role is required']"
            label="Select User Role" required>
          </v-select>
          <v-select v-if="isSuperAdmin && user.userRole !== 'State Admin'" v-model="user.courthouse"
            :items="getClientConfig.courtInfo" :rules="[v => !!v || 'Courtname is required']" label="Courtname" required
            item-text="name" item-value="alias"></v-select>
          <v-text-field v-else v-model="user.courthouse" label="Courthouse" disabled>
          </v-text-field>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" outlined @click="show = !show" width="175">
              <v-icon left>mdi-close</v-icon>
              Close
            </v-btn>
            <v-btn color="primary" @click="updateUser" width="175">
              <v-icon left>mdi-content-save-cog</v-icon>
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
      <v-card-actions v-if="actionType === 'delete'">
        <v-spacer></v-spacer>
        <v-btn color="error" outlined @click="show = !show" width="175"><v-icon left>mdi-close</v-icon>Close</v-btn>
        <v-btn color="primary" @click="confirmDeleteUser" width="175"><v-icon left>mdi-delete</v-icon>Delete</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'editAndDeleteUser',
  props: {
    userIndex: Object,
    userType: String
  },
  data() {
    return {
      show: null,
      actionType: '',
      formTitle: '',
      loading: false,
      user: {
        name: '',
        email: '',
        userRole: '',
        courthouse: '',
        id: null,
      },
      rules: {
        nameRules: [
          v => !!v || 'Full name is required',
        ],
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
      }
    }
  },
  methods: {
    editUser() {
      this.formTitle = "Edit User";
      this.actionType = 'edit';
      //this.editUserIndex = this.allRegisteredUsers.indexOf(this.user);
      this.user = Object.assign({}, this.userIndex);
      this.user.id = this.userIndex.id;
    },
    deleteUser() {
      this.formTitle = "Are you sure you want to delete this user?";
      this.actionType = 'delete';
    },
    updateUser() {
      if (this.$refs.editUserForm.validate()) {
        this.loading = true;
        if (this.userType === 'registered') {
          this.$store.dispatch('updateRegisteredUserInformation', this.user)
            .then(response => {
              this.loading = false;
              this.show = false;
              this.$store.dispatch('showSnackBar', {
                text: response,
                color: 'success'
              })
            }, error => {
              this.$store.dispatch('showSnackBar', {
                text: error,
                color: 'error'
              })
            })
        }
        if (this.userType === 'invited') {
          this.$store.dispatch('updateInvitedUserInformation', this.user)
            .then(response => {
              this.loading = false;
              this.show = false;
              this.$store.dispatch('showSnackBar', {
                text: response,
                color: 'success'
              })
            }, error => {
              this.$store.dispatch('showSnackBar', {
                text: error,
                color: 'error'
              })
            })
        }

      }
    },
    confirmDeleteUser() {
      this.loading = true;
      if (this.userType === 'registered') {
        this.$store.dispatch('deleteRegisteredUser', this.userIndex)
          .then(response => {
            this.loading = false;
            this.show = false;
            this.$store.dispatch('showSnackBar', {
              text: response,
              color: 'success'
            })
          }, error => {
            this.$store.dispatch('showSnackBar', {
              text: error,
              color: 'error'
            })
          })
      }
      if (this.userType === 'invited') {
        this.$store.dispatch('deleteInvitedUser', this.userIndex.id)
          .then(response => {
            this.loading = false;
            this.show = false;
            this.$store.dispatch('showSnackBar', {
              text: response,
              color: 'success'
            })
          }, error => {
            this.$store.dispatch('showSnackBar', {
              text: error,
              color: 'error'
            })
          })
      }
    }

  },
  computed: {
    ...mapGetters(['userRoles', 'allRegisteredUsers', 'allRegisteredKiosks', 'isSuperAdmin', 'assignedRole', 'getClientConfig', 'isAdmin', 'isClientAdmin']),
    roles() {
      let roleName = [];
      if (this.isSuperAdmin) {
        this.userRoles.forEach(role => roleName.push(role.data.name));
      } else if (this.isAdmin) {
        this.userRoles.forEach(role => {
          if (role.data.name !== 'Super Admin' && role.data.name !== 'State Admin') {
            roleName.push(role.data.name)
          }
        })
      } else if (this.isClientAdmin) {
        this.userRoles.forEach(role => {
          if (role.data.name !== 'Super Admin') {
            roleName.push(role.data.name)
          }
        })
      } else {
        //
      }

      return roleName;
    },
    kiosks() {
      let kioskName = [];
      this.allRegisteredKiosks.forEach(kiosk => kioskName.push(kiosk.data().name))
      return kioskName;
    }
  }
}
</script>