<template>
  <v-dialog v-model="show" width="45%" persistent>
    <template v-slot:activator="{ on, attrs }" v-if="assignedRole.data.permissions.user.add">
      <v-btn v-bind="attrs" v-on="on" elevation="0" color="primary">
        <v-icon left>
          mdi-account-plus
        </v-icon>
        Create New User
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="justify-center">
        Create New User
      </v-card-title>
      <v-divider></v-divider>
      <v-progress-linear v-show="loading" indeterminate color="primary" background-opacity="0">
      </v-progress-linear>
      <v-alert dense type="info" class="white--text alertBorder" width="100%" v-if="isClientAdmin">
        Role <strong>State Admin</strong> should be given to the users which are not specific to any courthouse. <br>
        A <strong>State Admin</strong> could not be registered for Telepresence.
      </v-alert>
      <v-card-text>
        <v-form ref="inviteNewUserForm" lazy-validation class="px-6">
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field v-model="user.name" label="Full Name" :rules="rules.nameRules" required>
              </v-text-field>
              <v-text-field v-model="user.email" label="E-mail" :rules="rules.emailRules" required>
              </v-text-field>
              <v-select v-model="user.userRole" :items="roles" :rules="[v => !!v || 'User role is required']"
                label="User Role" required>
              </v-select>
              <!-- <v-select v-if="(isSuperAdmin || isClientAdmin)  && user.userRole !== 'State Admin'" v-model="user.courthouse"
                :items="getClientConfig.courtInfo" :rules="[v => !!v || 'Courtname is required']" label="Courtname"
                required item-text="name" item-value="alias"></v-select> -->
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12">
              <v-card-text align="center">
                <p class="font-weight-bold"> <v-icon left color="primary">mdi-information-outline</v-icon> Invitation link
                  sent to the user's email will be use to set the password and activate the account.</p>
              </v-card-text>
            </v-col>
            <v-col cols="12" align="end">
              <v-btn color="error" dark large @click="closeCreateNewUserDialog()" class="mx-2" width="175" outlined>
                <v-icon left>mdi-close</v-icon>
                Close
              </v-btn>
              <v-btn color="primary" dark large @keyup.enter="inviteNewUser" @click="inviteNewUser" width="175">
                <v-icon left>mdi-plus</v-icon>
                Invite User
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
  
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'createNewUser',
  data() {
    return {
      show: null,
      loading: false,
      user: {
        name: '',
        email: '',
        userRole: '',
        //courthouse: '',
        fdn: window.location.hostname,
      },
      rules: {
        nameRules: [
          v => !!v || 'Full name is required',
        ],
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
      }
    }
  },
  methods: {
    inviteNewUser() {
      if (this.$refs.inviteNewUserForm.validate()) {
        this.loading = true;
        // if (this.user.userRole === 'State Admin' || this.user.userRole === 'Super Admin') {
        //   this.user.courthouse = 'NA'
        // } else {
        //   if (!this.isSuperAdmin && !this.isClientAdmin) {
        //     this.user.courthouse = this.userProfile.data().courthouse
        //   }
        // }
        this.$store.dispatch('inviteNewUser', this.user)
          .then(response => {
            this.loading = false;
            this.show = false;
            this.$store.dispatch('showSnackBar', {
              text: response,
              color: 'green'
            })
            //this.$refs.inviteNewUserForm.reset()
          }, error => {
            this.$store.dispatch('showSnackBar', {
              text: error,
              color: 'error'
            })
          })
      }
    },
    closeCreateNewUserDialog() {
      this.show = false
      this.$refs.inviteNewUserForm.reset()
    }
  },
  computed: {
    ...mapGetters(['userRoles', 'allRegisteredKiosks', "assignedRole", "getClientConfig", "isSuperAdmin", 'userProfile', 'isClientAdmin', 'isAdmin']),
    roles() {
      let roleName = [];
      if (this.isSuperAdmin) {
        this.userRoles.forEach(role => roleName.push(role.data.name));
      } else if (this.isAdmin) {
        this.userRoles.forEach(role => {
          if (role.data.name !== 'Super Admin' && role.data.name !== 'State Admin') {
            roleName.push(role.data.name)
          }
        })
      } else if (this.isClientAdmin) {
        this.userRoles.forEach(role => {
          if (role.data.name !== 'Super Admin') {
            roleName.push(role.data.name)
          }
        })
      } else {
        //
      }

      return roleName;
    },
    kiosks() {
      let kioskName = [];
      this.allRegisteredKiosks.forEach(kiosk => kioskName.push(kiosk.data().name))
      return kioskName;
    }
  },
  mounted() {
    if (this.userRoles.length === 0) {
      this.$store.dispatch('getUserRoles')
        .then((response) => {
          console.log(response)
        }, error => {
          console.error(error);
        })
    }
    if (this.allRegisteredKiosks.length === 0) {
      this.$store.dispatch('getAllRegisteredKiosks')
        .then((response) => {
          console.log(response)
        }, error => {
          console.error(error);
        })
    }
  }
}
</script>
<style>
.alertBorder {
  border-radius: 0px !important;
}
</style>